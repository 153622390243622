<template>
    <div class="sms_template_list_add">
        <el-card>
            <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
            <div class="flex">
                <el-form :model="form" label-width="120px" style="flex:1">
                    <el-form-item label="标题">
                        <el-col :span="8">
                            <el-input placeholder="请输入标题" v-model="form.title"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="模板内容">
                        <div>
                            <el-col :span="12">
                                <el-input
                                    placeholder="请输入模板内容"
                                    :rows="8"
                                    type="textarea"
                                    v-model="form.content"
                                    show-word-limit
                                    maxlength="490"
                                ></el-input>
                            </el-col>
                        </div>
                        <div style="clear: both; line-height: 1.8; width: 50%;padding-top: 10px;color: #fda400;">
                            <div class="tips">
                                当前 <span>{{ form.content.length }}</span> 字，预计发送条数约为
                                <span>{{ contentArticle }}</span>
                                条。实际发送时，签名、变量长度会影响总字数。具体计费条数请<span
                                    >以模板审核通过后发送测试为基准</span
                                >
                            </div>
                            <div>
                                【注意事项】<br />
                                1、文案前面不用加[扬帆出海]，发出时会自动加上 <br />
                                2、文案最后需要手动加上“拒收请回复R” <br />
                                3、链接前后需要空格<br/>
                              4、文案中不要出现【】符号

                            </div>
                          <span style="color: red ">
                         示例： CJ游友必打卡！[7.24-7.27]与扬帆出海开启4日上海GAME之旅！1场游戏主题峰会+3大主题派对，集结数百家海内外头部厂商，汇聚5000+游戏行业出海精英，资讯/干货/人脉/合作/壕礼统统拿下！即刻报名： http://yfch.cc/eP88m9 ，拒收请回复R
                        </span>
                        </div>
                      <div style="width: 50%">

                      </div>
                    </el-form-item>
                    <el-form-item label="申请说明">
                        <el-col :span="12">
                            <el-input
                                type="textarea"
                                :rows="8"
                                v-model="form.remark"
                                placeholder="描述您的业务场景有助于提高模板审核通过率。说明业务场景是什么，发送对象是谁，每个变量内容是什么。
若存在已通过的相同内容模板，请备注已审核通过的模板ID。"
                            />
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="add">{{ "保存" }}</el-button>
                        <!-- <el-button size="small" @click="preview">{{ "预览" }}</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-dialog :visible.sync="show" width="750px"> </el-dialog>
    </div>
</template>

<script>
import fuzzySelection from "@/components/fuzzySelection";
export default {
    components: {
        fuzzySelection,
    },
    data() {
        return {
            form: {
                title: "",
                content: "",
                remark: "",
            },
        };
    },
    watch: {},
    created() {
        this.id = this.$route.query.id;
        this.getForm();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getForm() {
            if (!this.id) return;
            let { data: res } = await this.$http.get("/admin/Sms/getTemplateById", { params: { id: this.id } });
            if (res.errorCode == 200) {
                this.form = res.data;
            }
        },
        pleaseSelect() {},
        showEdit(row) {
            this.form.topId = row.id;
            this.form.topTitle = row.title;
        },
        async add() {
            var url = "/admin/Sms/createTemplate";
            if (this.id) {
                url = "/admin/Sms/editTemplate";
            }
            let { data: res } = await this.$http.post(url, this.form);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$router.go(-1);
            }
        },
        preview() {
            this.show = true;
        },
        select() {
            this.$refs.pop.init(this.form.topType, this.form.topId);
        },
    },
    computed: {
        contentArticle() {
            var length = this.form.content.length;
            return Math.ceil(length / 67);
        },
    },
};
</script>

<style lang="less" scoped>
.for {
    transform: scale(0.5);
    transform-origin: 0 0;
}
.tips {
    color: gray;
    span {
        color: red;
    }
}
</style>
